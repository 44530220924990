<template>
  <validation-observer ref="formValidator"> 
    

    <form v-on:submit.prevent="next" class="p-4" >

      <div class="col-md-12 text-center" v-if="!showRelatedFees">
      <h4>Do you want to add connection & related fees now?</h4>
      <button type="button" class="btn btn-success mr-4" @click="yes()"> Yes </button>
      <button type="button" class="btn btn-primary" @click="next()"> No </button>
    </div>

      <div class="row" v-else>
        <div class="col-md-12">
          <table class="table">
            <thead>
              <tr>
                <th>Item Name</th>
                <th>Fee</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(val, index) in newApplication.related_fees" :key="index">
                <td>
                  <select class="form-control" v-model="val.item">
                    <option value="">Select Fee Type</option>
                    <option v-for="(newFey, fKey) in feeTypes" :key="fKey" :value="newFey">{{ newFey }}</option>
                  </select>
                </td>
                <td>
                  <input type="number" v-model="val.fee" class="form-control" required>
                </td>
                <td>
                  <a v-if="index > 0" href="javascript:void(0)" class="text-danger" @click="removeItem(index)"><i class="fa fa-trash-o fa-lg pt-2" aria-hidden="true"></i></a>
                </td>
              </tr>
              <tr>
                <th></th>
                <th>Total</th>
                <th>{{ calculateTotal() }}</th>
              </tr>
            </tbody>
          </table>          
        </div>

        <div class="col-md-12 text-right">
          <button type="button" class="btn btn-primary btn-sm" @click="addMore()">+ Add More Item</button>
        </div>
      </div>

      <hr />

      <div class="row">
        <div class="col-md-12 text-right">
          <button type="button" class="btn btn-default" @click="back()">
            Back
          </button>
          <button type="submit" class="btn btn-primary">
            Next
          </button>
        </div>
      </div>
    </form>
  </validation-observer>
</template>
<script>
export default {
  name: "RelatedFees",
  props: {
    application: Object,
    activeTab: Number,
  },
  data() {
    return {
      showRelatedFees: false,
      newApplication: {},
      feeTypes:["Connection Charge (with vat)", "Asphalt/Pitch/Road Digging (with vat)", "Six months advance", "(Re-connection) Due and Surcharge", "Meter Fee", "Form Fee"]
    };
  },
  methods: {
    yes() {
      this.showRelatedFees = true;
      this.newApplication.related_fees = [{item:"", fee: ""}]
    },
    back() {
      this.$emit("formSubmitted", {
        data: this.newApplication,
        tab: 2,
      });
    },
    next() {
      this.$emit("formSubmitted", {
        data: this.newApplication,
        tab: 4,
      });
    },
    addMore() {
      this.newApplication.related_fees.push({item:"", fee:""});
    },   
    removeItem(index) {
      this.newApplication.related_fees.splice(index, 1);
    },
    calculateTotal() {
      let total = 0;
      this.newApplication.related_fees.forEach(element => {
        total += element.fee?parseFloat(element.fee):0; 
      });
      this.newApplication.total_fees = total.toFixed(2);
      return total.toFixed(2);
    }
  },
  mounted() {
    this.newApplication = { ...this.application };
    if(this.newApplication.related_fees.length) {
      this.showRelatedFees = true;
    }
  },
};
</script>
