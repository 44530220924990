<template>
  <validation-observer ref="formValidator" v-slot="{ handleSubmit }">
    <form v-on:submit.prevent="handleSubmit(next)" class="p-4">
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-4">
              <div class="form-group">
                <label>Country</label>
                <select
                  name="state"
                  v-model="newApplication.country"
                  class="form-control"
                  required
                  @change="getDivisions()"
                >
                  <option value="">Select Country</option>
                  <option
                    v-for="(country, key) in locations"
                    :key="key"
                    :value="country.name"
                    >{{ country.name }}</option
                  >
                </select>
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <label>State</label>
                <select
                  name="state"
                  v-model="newApplication.division"
                  class="form-control"
                  required
                  @change="getCities()"
                >
                  <option value="">Select State</option>
                  <option
                    v-for="(division, key) in divisions"
                    :key="key"
                    :value="division.name"
                    >{{ division.name }}</option
                  >
                </select>
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <label>City</label>
                <select
                  name="city"
                  v-model="newApplication.city"
                  class="form-control"
                  @change="getAreas()"
                  required
                >
                  <option value="">Select City</option>
                  <option
                    v-for="(item, key) in cities"
                    :key="key"
                    :value="item.name"
                    >{{ item.name }}</option
                  >
                </select>
              </div>
            </div>

            <div class="col-md-4">
              <div class="form-group">
                <label>Area</label>
                <select
                  name="area"
                  v-model="newApplication.area"
                  class="form-control"
                  required
                >
                  <option value="">Select Area</option>
                  <option
                    v-for="(item, key) in areas"
                    :key="key"
                    :value="item"
                    >{{ item }}</option
                  >
                </select>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Ward Number</label>
                <input
                  type="text"
                  class="form-control"
                  v-model="newApplication.ward_number"
                  placeholder="Ward Number"
                  required
                />
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <label>Address</label>
                <textarea
                  name="address"
                  class="form-control"
                  v-model="newApplication.address"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div class="row">
        <div class="col-md-12 text-right">
          <button type="button" class="btn btn-default" @click="back()">
            Back
          </button>
          <button type="submit" class="btn btn-primary">
            Next
          </button>
        </div>
      </div>
    </form>
  </validation-observer>
</template>
<script>
import locations from "../../../assets/json/locations.json";
export default {
  name: "AddressInfo",
  props: {
    application: Object,
    activeTab: Number,
  },
  data() {
    return {
      locations: locations,
      newApplication: {
        city: "",
        division: "",
        country: "",
      },
      divisions: [],
      cities: [],
      areas: [],
    };
  },
  methods: {
    getDivisions(resetData) {
      resetData = resetData?resetData:false;
      if(resetData) {
        this.newApplication.division = "";
        this.newApplication.city = "";
        this.newApplication.area = "";
        this.cities = [];
        this.areas = [];
      }
      let country = this.locations.find((item) => {
        if (item.name === this.newApplication.country) {
          return item.states;
        }
      });
      this.divisions = country.states;
    },
    getCities(resetData) {
      resetData = resetData?resetData:false;
      if(resetData) {
        this.newApplication.city = "";
        this.newApplication.area = "";
        this.areas = [];
      }
      let division = this.divisions.find((item) => {
        if (item.name === this.newApplication.division) {
          return item;
        }
      });
      this.cities = division.districts;
    },
    getAreas(resetData) {
      resetData = resetData?resetData:false;
      if(resetData) {
        this.newApplication.area = "";
      }
      let city = this.cities.find((item) => {
        if (item.name === this.newApplication.city) {
          return item;
        }
      });
      this.areas = city.areas;
    },
    back() {
      this.$emit("formSubmitted", {
        data: this.newApplication,
        tab: 1,
      });
    },
    next() {
      this.$emit("formSubmitted", {
        data: this.newApplication,
        tab: 3,
      });
    },
    resetCityArea() {
      this.newApplication.city = "";
      this.newApplication.area = "";
    },
  },
  mounted() {
    this.newApplication = { ...this.application };
    // this.locations = this.locations.filter((item) => item.name === this.orgInfo.country);
    if(this.newApplication.id) {
      this.getDivisions(false);
      this.getCities(false);
      this.getAreas(false);
    }
  },
};
</script>
